<template src="./activityMySubjects.html"></template>

<script>
import TimetableRepository from "../../Repository/timetable";
import SubjectsRepository from "../../Repository/Subjects";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SemesterRepository from "../../Repository/Semester"
import { subjectTypes } from "../../Constants/Utils/Statics";
// import { visitedRouteName } from '../../Router/index';
import auth from '../../Services/Firebase/auth'
import UserRepository from "../../Repository/User";
import UserAuthenticationRepository from "../../Repository/UserAuthentication";
import ValueAddedActivityRepository from "../../Repository/valueAddedActivity";
// import VueTour from 'vue-tour'
export default {
  name: "activityMySubjects",
  data() {
    return {
      // checkIsTermAccepted: false,
      // selected: [],
      // notSelected: [],
      // dialog2: false,
      // dialog1: false,
      // userAuthData: [],
      isAspireInstitute: false,
      allValueAddedActivity: [],
      praLoder: false,
      progressLoader: false,
      groupObjectArr: [],
      subjectSelectedByStudent: [],
      sortedElectiveSubjectToChoose: [],
      electiveSubjectToChoose: [],
      dialogElective: false,
      checkIsTermAccepted: false,
      selected: [],
      notSelected: [],
      dialog2: false,
      dialog1: false,
      userAuthData: [],
      subModuleToShow: [],
      studentAccess: [],
      visitedRoutesDummy: [],
      studentInformation: [],
      routeName: '',
      isFormModalOpen: false,
      formLink: '',
      show: false,
      userData: null,
      selectedInstitute: "",
      selectedSemester: "",
      subjects: [],
      isMobileView: false,
      isFetchingSubjects: false,
      steps: [
        {
          target: '#study',
          content: `Check study material here`,
          params: {
            placement: 'bottom',
            enableScrolling: false
            // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#assignment',
          content: 'Check/Submit assignment',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#course',
          content: 'Course related material here',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      grouData: {}
    }
  },
  async created() {
    // UserAuthentication
    // console.log('created');
    this.userRepositoryInstance = new UserRepository(this)
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)
    this.valueAddedActivityRepositoryInstance = new ValueAddedActivityRepository(this)
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    const terms = this.$store.getters["liveData/get_isTermsAccepted"];
    console.log('terms', terms);
    // console.log('this.isMobileView', this.isMobileView);
    this.userData = this.$store.getters["user/get_userData"];
    // console.log('this.userData1', this.userData);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.studentAccess = this.$store.getters[
      "instituteData/get_studentAccess"
    ];
    // console.log('this.studentAccess5', this.studentAccess);
    this.subModuleToShow = this.studentAccess.studentAccess[2].subModules
    const idNamesForVueTour = ["study", "assignment", "course"]
    this.subModuleToShow.map((data, i) => {
      data.id = idNamesForVueTour[i]
    })
    // console.log('this.subModuleToShow', this.subModuleToShow);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.timetableRepositoryInstance = new TimetableRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.SemesterRepositoryInstance = new SemesterRepository(this);
    let name = 'My Subjects'
    if (process.env.VUE_APP_WHITELABEL_NAME === 'sppu') {
      name = 'My Courses'
    }
    if (process.env.VUE_APP_WHITELABEL_NAME === 'aspire') {
      name = 'Select Program'
      this.isAspireInstitute = true
    }
    this.$store.commit("liveData/set_selectedActivityName", name);
    await this.fetchData();
    this.startSiteTour()
  },
  methods: {
    startCourse() {
      this.dialog1 = false
      this.dialog2 = true
    },
    cancelCourse() {
      this.dialog1 = false
      this.logout()
    },
    disAgreeTerms() {
      this.dialog2 = false
      this.logout()
    },
    async agreeTerms() {
      this.dialog2 = false
      const objToPush1 = {
        uId: this.userData.uId,
        isTermsAgree: true
      }
      // console.log('objToPush1', objToPush1);
      try {
        const response = await this.UserAuthenticationRepositoryInstance.updateVisitedRoutes(objToPush1)
        console.log('response', response);

        this.$store.commit("liveData/set_isTermsAccepted", true);
        this.checkIsTermAccepted = this.$store.getters["liveData/get_isTermsAccepted"];
        // console.log('this.$store', this.$store);

        // this.$router.push("/activityChangeSemester")
      } catch (error) {
        console.log(error)
      }
    },

    async saveSelectedElective() {
      // let subjectIdArr = []
      this.sortedElectiveSubjectToChoose.map((grp) => {
        if (grp.selectedSubjects !== '') {
          if (this.userData.subjects && Array.isArray(this.userData.subjects) && !this.userData.subjects.includes(grp.selectedSubjects)) {
            this.userData.subjects.push(grp.selectedSubjects)
          }
          // console.log('grp', grp);
        }
      })
      // console.log('this.userData4', this.userData);
      const obj = {}
      obj.instituteId = this.userData.instituteId
      obj.semId = this.userData.semId
      obj.uId = this.userData.uId
      obj.department = this.userData.department
      obj.courseYear = this.userData.courseYear
      obj.division = this.userData.division
      obj.batch = this.userData.batch
      obj.subjects = this.userData.subjects
      try {
        const res = await this.semesterUserRepositoryInstance.updateSubjects(this.userData)
        console.log('res', res);

        await this.fetchData()
      } catch (error) {
        console.log(error);
      }
      this.dialogElective = false
    },
    sliceDate(date) {
      // console.log('date.slice(0, 10)', date.slice(0, 10));
      return date.slice(0, 10)
    },
    gotoRouteIndexWise(subject, i) {
      // console.log('subject', subject);
      const groupData = this.userData.assignedGroupForSubject.find(group => group.subjectId === subject.subjectId);
      this.groupData = groupData
      // console.log('groupData', this.groupData)
      if (i === 0) {
        this.gotoStudyMaterial(subject)
      }
      if (i === 1) {
        this.gotoAssignments(subject)
      }
      if (i === 2) {
        this.gotoCourseMaterial(subject)
      }
    },
    async startSiteTour() {
      this.studentInformation = [];
      this.routeName = this.$route.name
      const objToPush = {
        uId: this.userData.uId
      }
      this.studentInformation = await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(objToPush)
      if (!this.studentInformation.userAuthentications[0].visitedRoutes.includes(this.routeName)) {
        setTimeout(() => {
          this.$tours[this.routeName].start()
        }, 1);
        this.studentInformation.userAuthentications[0].visitedRoutes.push(this.routeName)
        this.visitedRoutesDummy = [...this.studentInformation.userAuthentications[0].visitedRoutes]
        // console.log("studentInformation", this.studentInformation)
        const objToPush1 = {
          uId: this.userData.uId,
          visitedRoutes: this.visitedRoutesDummy
        }
        await this.UserAuthenticationRepositoryInstance.updateVisitedRoutes(objToPush1)
      }
    },
    async fetchData() {
      // console.log('fetch');
      this.progressLoader = true
      // console.log('this.progressLoader12', this.progressLoader);
      try {
        this.allValueAddedActivity = []
        this.allValueAddedActivity = await this.valueAddedActivityRepositoryInstance.getValueAddedActivityAll({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department
        })
        // console.log('this.allValueAddedActivity', this.allValueAddedActivity);

      } catch (error) {
        console.log(error);
      }
      try {
        const objToPush2 = {
          uId: this.userData.uId
        }
        this.userAuthData = await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(objToPush2)
        if (process.env.VUE_APP_WHITELABEL_NAME === 'sppu' && this.userAuthData.userAuthentications[0].isTermsAgree === false) {
          this.dialog1 = true
        }
        const subjectsFromStore = this.$store.getters['mappings/get_subjectsMapping']
        const subjectsFromStoreToArray = Object.keys(subjectsFromStore).map(subjectId => subjectsFromStore[subjectId])
        if (Array.isArray(subjectsFromStoreToArray) && subjectsFromStoreToArray.length > 0) {
          this.subjects = subjectsFromStoreToArray;
          // return
        }
        // this.subjects = []
        this.isFetchingSubjects = true
        let subjects = []
        subjects = await this.subjectsRepositoryInstance.getSubjects({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: [...this.userData.subjects]
        })
        // console.log('subjects', subjects);
        for await (const subject of subjects) {
          subject.subjectType = subjectTypes[subject.subjectType]
          this.$store.commit("mappings/add_subjectsMapping", subject);
        }
        subjects.map((data) => {
          data['isElective'] = false
          if (data.subjectType && Array.isArray(data.subjectType) && data.subjectType.includes('Elective')) {
            data['isElective'] = true
          }
        })
        this.subjects = [...subjects, ...this.allValueAddedActivity]
        this.subjects.sort((a, b) => {
            const subjectNameA = a.subjectName.toUpperCase();
            const subjectNameB = b.subjectName.toUpperCase();

            if (subjectNameA < subjectNameB) {
              return -1;
            }
            if (subjectNameA > subjectNameB) {
              return 1;
            }
            return 0;
          });
        let electiveSubject = []
        electiveSubject = await this.subjectsRepositoryInstance.getSubjectsOfACourseYear({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department,
          courseYear: this.userData.courseYear
        })

        this.electiveSubjectToChoose = []
        electiveSubject.map((sub) => {
          sub['alreadyAddedInSubject'] = false
          if (this.userData.subjects && Array.isArray(this.userData.subjects) && this.userData.subjects.includes(sub.subjectId)) {
            sub['alreadyAddedInSubject'] = true
          }
          if (sub.subjectType > 3 && sub.allowStudentToSelect === true) {
            this.electiveSubjectToChoose.push(sub)
          }
        })
        const groupName = []
        this.electiveSubjectToChoose.map((eleSub) => {
          groupName.push(eleSub.groupName)
        })
        this.groupObjectArr = [...new Set(groupName)]

      } catch (err) {
        console.log(err);
        alert("An error occured, refresh or try again later")
      } finally {
        this.isFetchingSubjects = false
        this.progressLoader = false
      }

    },

    syncWithsorted() {
      this.sortedElectiveSubjectToChoose = []
      // console.log('this.groupObjectArr', this.groupObjectArr);
      this.groupObjectArr.map(async (grp) => {
        const obj = {}
        obj['groupName'] = grp
        obj['subjects'] = []
        obj['selectedSubjects'] = ''
        obj['disabledObject'] = false
        await this.electiveSubjectToChoose.map((Sub) => {
          // console.log('this.userData.subjects', this.userData.subjects);
          if (grp === Sub.groupName) {
            obj.subjects.push(Sub)
          }
        })
        await obj.subjects.map((selectedSub) => {
          if (selectedSub.alreadyAddedInSubject === true) {
            obj['disabledObject'] = true
            obj['selectedSubjects'] = selectedSub.subjectId
          }
        })
        this.sortedElectiveSubjectToChoose.push(obj)
      })
      this.dialogElective = true
    },
    gotoAttendanceAnalysis() {
      if (this.isMobileView) {
        this.$store.commit('liveData/set_selectedActivity', 4)
        this.$router.push('/activityAttendanceAnalysis2')
      } else {
        this.$store.commit("liveData/set_selectedActivity", 5);
        this.$router.push("/activityAttendanceAnalysis");
      }
    },
    openCourses(subject) {
      // console.log('subject', subject);
      // console.log('this.isFormModalOpen', this.isFormModalOpen);
      this.isFormModalOpen = true
      const FORM_VIEWER = new URL('https://courses-student.vercel.app/view-single-course')
      FORM_VIEWER.searchParams.set('instituteId', this.selectedInstitute)
      FORM_VIEWER.searchParams.set('uId', this.userData.uId)
      FORM_VIEWER.searchParams.set('subjectId', subject.subjectId)
      this.formLink = FORM_VIEWER
      // console.log("FORM_VIEWER", FORM_VIEWER);
    },
    gotoStudyMaterial(subject) {
      // console.log('subjectStudy', subject);
      if (this.isMobileView) {
        this.$router.push({
          name: "activityStudyMaterial2",
          params: {
            prop_subject: subject
          }
        });
      } else {
        this.$router.push({
          name: "activityStudyMaterial",
          params: {
            prop_subject: subject,
            prop_groupData: this.groupData
          }
        });
      }
    },
    gotoAssignments(subject) {
      // console.log('subjectAssignment', subject);
      if (this.isMobileView) {
        this.$router.push({
          name: "activityAssignments2",
          params: {
            prop_subject: subject
          }
        });
      } else {
        this.$router.push({
          name: "activityAssignments",
          params: {
            prop_subject: subject
          }
        });
      }
    },
    gotoCourseMaterial(subject) {
      // console.log('subject', subject);
      // console.log('sas', this.selectedSemester);
      subject.courseYear = this.selectedSemester.courseYear
      subject.department = this.selectedSemester.department
      subject.division = this.selectedSemester.division
      if (this.isMobileView) {
        this.$router.push({
          name: "activityCourseMaterialRevamped2",
          params: {
            prop_subject: subject,
            prop_subjects: this.subjects
          }
        });
      } else {
        // console.log('subject', subject);
        // console.log('this.subjects', this.subjects);
        this.$router.push({
          name: "activityCourseMaterialRevamped",
          params: {
            prop_subject: subject,
            prop_subjects: this.subjects
          }
        });
      }
    },
    logout() {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      this.$store.commit('mappings/reset')
      this.$store.commit('liveData/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    }
  }
};
</script>

<style scoped src="./activityMySubjects.css"></style>
