<template src="./activityExamForm.html"></template>

<script>
import SubjectRepository from "../../Repository/Subjects";
import apiV3 from "../../NetworkManager/apiV3";
import showStatus from "../../NetworkManager/showStatus";

export default {
  name: "activityExamForm",
  props: ["prop_exam", "prop_institute"],
  data() {
    return {
      userData: {},
      fullNameOfUser: "",
      studentEmail: "",
      contactNumber: "",
      studentGender: "",
      studentCategory: "",
      approvalStatus: false,
      feeStatus: false,
      examFormLoader: false,
      examFormInstructionsForStudent: "",
      studentSubjects: [],
      subjectInfo: [],
      dataLoader: false,
      signatureIndividuals: [],
      examFormFields: [],
      subjectDetailFields: [],
      studentRollNo: "",
      prnNumber: "",
      isCurrentInstituteIsRiims: false,
      isStmiras: false,
      studentPersonalDetails: {},
      selectedSemester: {},
      isMima: false,
      examSeatNumber: ""
    };
  },
  async created() {
    if (this.prop_institute.instituteId === "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b") {
      this.isMima = true
    }
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.$store.commit("liveData/set_selectedActivityName", "Exam Form");
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.userData = this.$store.getters["user/get_userData"];
    if (this.userData.instituteId === "2939ed29-4649-4043-8498-f4eb3b0eac9e") {
      this.isCurrentInstituteIsRiims = true
    }
    if (this.userData.instituteId === "6604982c-cc08-4e00-81fb-a375a10b72e4") {
      this.isStmiras = true
    }
    this.studentRollNo = this.userData.rollNumber
    this.examSeatNumber = this.userData.examSeatNumber
    const subjectIds = [];
    for (let index = 0; index < this.prop_exam.examSubjectsAndQuestions.length; index++) {
      const subjectDetail = this.prop_exam.examSubjectsAndQuestions[index];
      if (this.userData.subjects.includes(subjectDetail.subjectId)) {
        subjectIds.push(subjectDetail.subjectId);
      }
    }
    console.log('subjectIds', subjectIds);
    await this.getSubjectDetails(subjectIds);
    this.getStudentSubjects();
    this.handleData();
  },
  methods: {
    getStudentSubjects() {
      for (let index = 0; index < this.subjectInfo.length; index++) {
        const element = this.subjectInfo[index];
        this.studentSubjects.push({
          subjectCode: element.subjectCode,
          subjectName: element.subjectName,
          subjectCredits: element.credits
        });
      }
      this.sortByNumericValue(this.studentSubjects, 'subjectCredits', false)
    },
    sortByNumericValue(data, prop, isAsc) {
      return data.sort((a, b) => {
        return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1)
      });
    },
    sortByAlphaNumericValue(array, fieldToSortBy) {
      array.sort((a, b) => {
        if (
          a[fieldToSortBy] === null ||
          b[fieldToSortBy] === null ||
          a[fieldToSortBy] === undefined ||
          b[fieldToSortBy] === undefined
        ) {
          // If either fieldToSortBy is null, move the element with null fieldToSortBy to the end.
          if (a[fieldToSortBy] === null || a[fieldToSortBy] === undefined) {
            return 1;
          }
          if (b[fieldToSortBy] === null || b[fieldToSortBy] === undefined) {
            return -1;
          }
        }
        return a[fieldToSortBy].localeCompare(b[fieldToSortBy]); // Compare non-null fieldToSortBy strings
      });
      return array;
    },
    handleData() {
      if (this.userData.middleName === "") {
        this.fullNameOfUser =
          this.userData.firstName + " " + this.userData.lastName;
      } else {
        this.fullNameOfUser =
          this.userData.firstName +
          " " +
          this.userData.middleName +
          " " +
          this.userData.lastName;
      }
      this.studentEmail = this.userData.email ? this.userData.email : "-";
      this.prnNumber = this.userData.collegePRNNo
        ? this.userData.collegePRNNo
        : "-";
      this.contactNumber = this.userData.mobile ? this.userData.mobile : "-";
      this.studentGender = this.userData.gender ? this.userData.gender : "-";
      this.studentCategory = this.userData.category
        ? this.userData.category
        : "-";
      const tempFields = this.prop_institute.examFormFormat ? this.prop_institute.examFormFormat.formFields : []
      for (let i = 0; i < tempFields.length; i++) {
        const element = tempFields[i];
        const objToPush = {}
        if (element === 'Program Name') {
          objToPush.text = 'Program Name'
          objToPush.value = this.prop_exam.criteria ? this.prop_exam.criteria.department : this.prop_exam.department
        } else if (element === 'PUNCODE') {
          objToPush.text = 'PUNCODE'
          objToPush.value = this.prop_institute.puncode ? this.prop_institute.puncode : '-'
        } else if (element === 'Exam Name') {
          objToPush.text = 'Exam Name'
          objToPush.value = this.prop_exam.title
        } else if (element === 'Date') {
          objToPush.text = 'Date'
          objToPush.value = this.prop_exam.startDate
        }
        this.examFormFields.push(objToPush)
      }
      const tempSubFields = this.prop_institute.examFormFormat ? this.prop_institute.examFormFormat.subjectDetailFields : []
      for (let i = 0; i < tempSubFields.length; i++) {
        const element = tempSubFields[i];
        const objToPush = {}
        if (element === 'Subject Name') {
          objToPush.text = 'Subject Name'
          objToPush.value = 'subjectName'
        } else if (element === 'Subject Code') {
          objToPush.text = 'Subject Code'
          objToPush.value = 'subjectCode'
        } else if (element === 'CIE (Internal)') {
          objToPush.text = this.isCurrentInstituteIsRiims ? 'CCE (Internal)' : 'CIE (Internal)'
          objToPush.value = ''
        } else if (element === 'SEE (External)') {
          objToPush.text = this.isCurrentInstituteIsRiims ? 'ESE (External)' : 'SEE (External)'
          objToPush.value = ''
        }
        this.subjectDetailFields.push(objToPush)
      }
      const tempBasicDetailFields = this.prop_institute.examFormFormat ? this.prop_institute.examFormFormat.basicDetailsFields : []
      const customPersonalDetailFields = []
      for (let i = 0; i < tempBasicDetailFields.length; i++) {
        const element = tempBasicDetailFields[i];
        const objToPush = {}
        if (element === 'Name') {
          objToPush.text = 'Name'
          objToPush.value = 'fullName'
        } else if (element === 'Email-Id') {
          objToPush.text = 'Email-Id'
          objToPush.value = 'emailId'
        } else if (element === 'Contact Number') {
          objToPush.text = 'Contact Number'
          objToPush.value = 'contactNumber'
        } else if (element === 'Category') {
          objToPush.text = 'Category'
          objToPush.value = 'category'
        } else if (element === 'Gender') {
          objToPush.text = 'Gender'
          objToPush.value = 'gender'
        } else if (element === 'Roll Number') {
          objToPush.text = 'Roll Number'
          objToPush.value = 'rollNumber'
        } else if (element === 'PRN') {
          objToPush.text = 'PRN'
          objToPush.value = 'prnNumber'
        } else if (element === 'Batch') {
          objToPush.text = 'Batch'
          objToPush.value = 'batch'
        } else if (element === 'Semester') {
          objToPush.text = 'Semester'
          objToPush.value = 'semester'
        } else if (element === 'Exam Seat Number') {
          objToPush.text = 'Exam Seat Number'
          objToPush.value = 'examSeatNumber'
        }
        customPersonalDetailFields.push(objToPush)
      }
      this.studentPersonalDetails = {
        fullName: this.fullNameOfUser,
        emailId: this.studentEmail,
        contactNumber: this.contactNumber,
        gender: this.studentGender,
        category: this.studentCategory,
        subjects: this.studentSubjects,
        prnNumber: this.prnNumber,
        rollNumber: this.studentRollNo,
        batch: this.studentRollNo,
        examSeatNumber: this.examSeatNumber,
        semester: this.selectedSemester.semName
      }
      this.prop_institute.examFormFormat['customSubjectFields'] = this.subjectDetailFields
      this.prop_institute.examFormFormat['customExamFormFields'] = this.examFormFields
      this.prop_institute.examFormFormat['customPersonalDetailFields'] = customPersonalDetailFields
      this.signatureIndividuals = this.prop_institute.examFormFormat.signatureIndividual
      this.examFormInstructionsForStudent = this.prop_institute.examFormFormat ? this.prop_institute.examFormFormat.formInstructions : ''
      if (this.prop_exam.examFormDetails) {
        const result = this.prop_exam.examFormDetails.filter(
          (item) => item.uId === this.userData.uId
        )[0];
        this.approvalStatus = result.approvalStatus;
        this.feeStatus = result.feeStatus;
      } else {
        this.approvalStatus = false;
        this.feeStatus = false;
      }
    },
    async getSubjectDetails(subjectIds) {
      this.dataLoader = true;
      try {
        this.subjectInfo = await this.subjectRepositoryInstance.getSubjects({
          instituteId: this.userData.instituteId,
          semId: this.userData.semId,
          subjectIds: subjectIds
        });
      } catch (error) {
        console.log('error', error);
      }
      this.dataLoader = false;
    },
    async downloadExamForm() {
      this.examFormLoader = true;
      let flag = true
      if (!this.prop_institute.examFormFormat.subjectDetailFields) {
        flag = false
      }
      if (!this.prop_institute.examFormFormat.formFields) {
        flag = false
      }
      const objToPush = {
        instituteData: { ...this.prop_institute, isStmiras: this.isStmiras, isMima: this.isMima },
        studentData: [this.studentPersonalDetails],
        examData: this.prop_exam
      };
      if (flag) {
        try {
          const result = await apiV3.postRequest(
            "/manageExam/generateStudentExamForm",
            objToPush
          );
          this.downloadFileFromBuffer(result.data.pdfUrl.data); // pass the buffer of the file
          showStatus('Exam form downloaded successfully.', 2000, 'success', this)
        } catch (error) {
          console.error('error', error);
        }
      } else {
        showStatus('Error generating exam form. Please contact your admin.', 9000, 'error', this)
      }
      this.examFormLoader = false;

    },
    downloadFileFromBuffer(resultByte) {
      var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor

      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.fullNameOfUser}_${this.prop_exam.title}_Examination Form.pdf`;
      link.click();
      showStatus('Error fetching link for online lecture .', 1000, 'success', this)
    },
    gotoExams() {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityExams2"
        });
      } else {
        this.$router.push({
          name: "activityExams"
        });
      }
    }
  }
};
</script>

<style scoped src="./activityExamForm.css"></style>
