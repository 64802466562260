<template src='./activityHome.html'></template>
<script>
import { auth, sendQrDataToFireBase, sendEventQrDataToFireBase } from "../../Services/Firebase/auth";
import state from '../../Store/Modules/liveData'
import state2 from '../../Store/Modules/instituteData'
import PortalFooter from '../../Components/PortalFooter'
// qr code scanner
import { QrcodeStream } from 'vue-qrcode-reader'
// import SocketioService from '../../Services/Socket/socket.service';

export default {
  name: 'activityHome',
  components: {
    PortalFooter,
    QrcodeStream
  },
  data() {
    return {
      qrResultByLiveData: {},
      qrCodeStatusNumber: null,
      studentAccess: [],
      sppuInstituteIdFlag: false,
      isMobileView: false,
      selectedActivity: 1,
      userData: null,
      user: null,
      logoutDialog: false,
      state: state,
      state2: state2,
      drawer: false,
      formLink: {},
      brandingName: "",
      brandingLogo: "",
      isFormModalOpen: false,
      showQrCodeScanner: false,
      qrCodeScannerError: "",
      showQrAttendanceResult: false,
      attendanceResult: false,
      careerfirstChanges: '',
      camera: 'auto',
      fingerprint: ''
    }
  },
  created() {
    this.user = this.$store.getters['user/get_user']
    this.userData = this.$store.getters['user/get_userData']
    const instituteData = this.$store.getters['instituteData/selectedInstitute']
    this.brandingName = instituteData.name
    this.brandingLogo = instituteData.logo
    this.selectedSemester = this.$store.getters[
      "instituteData/get_selectedSemester"
    ];
    const iconNames = ['mdi-chart-timeline-variant-shimmer', 'mdi-animation-outline', 'mdi-book-open-variant', 'mdi-calendar-text', 'mdi-playlist-check', 'mdi-message-question-outline', 'mdi-comment-quote-outline']
    this.studentAccess = this.$store.getters[
      "instituteData/get_studentAccess"
    ];
    this.studentAccess.studentAccess.map((data, i) => {
      data.iconName = iconNames[i]
    })
    // this.socketioServiceInstance = new SocketioService(this)
    // this.socketioServiceInstance.setupSocketConnection()
    if (this.userData.instituteId === 'aeb2dd8e-bb6f-436a-945e-fb1c39e1b6c3') {
      this.sppuInstituteIdFlag = true
    }
    if (process.env.VUE_APP_WHITELABEL_NAME) {
      this.careerfirstChanges = process.env.VUE_APP_WHITELABEL_NAME
    }
    this.getMachineId()
  },
  // updated() {
  //   this.checkIsTermAccepted = this.$store.getters["liveData/get_isTermsAccepted"];
  // },
  beforeUnmount() {
    this.socketioServiceInstance.disconnect();
  },
  methods: {
    gotoSelectedBtn(i) {
      if (i === 0) {
        this.gotoMyDashboard(i)
      }
      if (i === 1) {
        this.gotoTimeline(i)
      }
      if (i === 2) {
        this.gotoMySubjects(i)
      }
      if (i === 3) {
        this.gotoMyTimetable(i)
      }
      if (i === 4) {
        this.gotoAttendanceAnalysis(i)
      }
      if (i === 5) {
        this.gotoExams(i)
      }
      if (i === 6) {
        this.gotoFeedbackform(i)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    gotoMyProfile() {
      this.$store.commit('liveData/set_selectedActivity', 1)
      this.$router.push('/activityProfile2')
    },
    gotoMySubjects(i) {
      this.$store.commit('liveData/set_selectedActivity', i)
      this.$router.push('/activityMySubjects2')
    },
    gotoMyTimetable(i) {
      this.$store.commit('liveData/set_selectedActivity', i)
      this.$router.push('/activityMyTimetable2')
    },
    gotoAttendanceAnalysis(i) {
      this.$store.commit('liveData/set_selectedActivity', i)
      this.$router.push('/activityAttendanceAnalysis2')
    },
    gotoTimeline(i) {
      this.$store.commit('liveData/set_selectedActivity', i)
      this.$router.push('/activityTimeline2')
    },
    gotoExams(i) {
      this.$store.commit('liveData/set_selectedActivity', i)
      this.$router.push('/activityExams2')
    },
    gotoTopicFeedback() {
      this.$store.dispatch('liveData/set_selectedActivity', 7)
      this.$router.push('/activityTopicFeedback2')
    },
    gotoChangeSemester() {
      this.$store.dispatch('liveData/set_selectedActivity', 8)
      this.$router.push('/activityChangeSemester2')
    },

    gotoMyDashboard(i) {
      this.$store.dispatch('liveData/set_selectedActivity', i)
      this.$router.push("/activityMyDashboard2");
    },

    gotoFeedbackForm(i) {
      this.$store.dispatch('liveData/set_selectedActivity', i)
      this.$router.push('/activityFeedbackList2')
    },

    /***gotoFeedbackform() {
      this.isFormModalOpen = true
      const FORM_VIEWER = new URL('https://feedback-form-studium.vercel.app/student')
      FORM_VIEWER.searchParams.set('importedInstituteId', this.selectedSemester.instituteId)
      FORM_VIEWER.searchParams.set('semId', this.selectedSemester.semId)
      FORM_VIEWER.searchParams.set('studentId', this.selectedSemester.uId)
      FORM_VIEWER.searchParams.set('departmentName', this.selectedSemester.department)
      FORM_VIEWER.searchParams.set('courseName', this.selectedSemester.courseYear)
      FORM_VIEWER.searchParams.set('divisionName', this.selectedSemester.division)
      this.formLink = FORM_VIEWER
    },**/
    logout() {
      this.logoutDialog = false
      this.$store.commit('user/reset')
      this.$store.commit('instituteData/reset')
      this.$store.commit('mappings/reset')
      this.$store.commit('liveData/reset')
      const user = window.localStorage.getItem("userKey")
      if (user) {
        window.localStorage.removeItem('userKey')
        this.$router.push('/activityWelcome')
      } else {
        auth.logout(this)
      }
    },
    async onInit(promise) {
      try {
        const { capabilities } = await promise
        console.log('capabilities', capabilities);
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.qrCodeScannerError = "user denied camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.qrCodeScannerError = "no suitable camera device installed"
        } else if (error.name === 'NotSupportedError') {
          this.qrCodeScannerError = "page is not served over HTTPS (or localhost)"
        } else if (error.name === 'NotReadableError') {
          this.qrCodeScannerError = "maybe camera is already in use"
        } else if (error.name === 'OverconstrainedError') {
          this.qrCodeScannerError = "did you requested the front camera although there is none?"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qrCodeScannerError = "browser seems to be lacking features"
        }
      } finally {
        // hide loading indicator
      }
    },
    getMachineId() {
      // Check if the machine ID is already stored in localStorage
      this.fingerprint = localStorage.getItem('machineId');

      if (!this.fingerprint) {
        // If machine ID doesn't exist, generate a pseudo-unique ID
        this.fingerprint = Math.random().toString(36).substr(2, 10);
        // Store the generated machine ID in localStorage
        localStorage.setItem('machineId', this.fingerprint);
      }
    },
    async onDecode(promise) {

      try {
        const qrData = JSON.parse(await promise)
        qrData['uId'] = this.userData.uId;
        qrData['status'] = 0;
        qrData['deviceId'] = this.fingerprint;
        qrData['timeOfScan'] = (new Date()).toISOString();
        if (qrData.eventId) {
          if (qrData.applicableInstitutes.includes(this.userData.instituteId)) {
            qrData.fullName = `${this.userData?.firstName || ''} ${this.userData?.middleName || ''} ${this.userData?.lastName || ''}`;
            qrData.instituteId = this.userData?.instituteId || '';
            qrData.instituteName = this.brandingName || '';
            qrData.semName = this.userData?.semName || '';
            qrData.department = this.userData?.department || '';
            qrData.courseYear = this.userData?.courseYear || '';
            qrData.division = this.userData?.division || '';
            qrData.collegePRNNo = this.userData?.collegePRNNo || '';
            qrData.rollNo = this.userData?.rollNumber || '';
            qrData.userName = this.userData?.userName || '';
          }
          console.log('qrData mobile', qrData);
          sendEventQrDataToFireBase(qrData)
        } else {
          sendQrDataToFireBase(qrData)
        }
        this.showQrCodeScanner = false
        this.pause()
        await this.timeout(200)
        this.unpause()
        // alert(qrData.token)
        setTimeout(this.showResult, 500)
      } catch (error) {
        console.error("QR Code Reader", error.message)
      }
    },
    showResult() {
      setTimeout(() => {
        try {
          this.qrResultByLiveData = this.$store.getters['liveData/get_attendanceInfoByQr']
          this.qrCodeStatusNumber = this.qrResultByLiveData.attendanceInfoByQr.status
          this.showQrAttendanceResult = true;
        } catch (error) {
          console.error('error')
          this.qrCodeStatusNumber = 0
          this.showQrAttendanceResult = true;
        }
      }, 300)
    },
    unpause() {
      this.camera = 'auto'
    },
    pause() {
      this.camera = 'off'
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  }
}
</script>
<style scoped src='./activityHome.css'></style>
