<template src="./activitySubmitAssessment.html"></template>
<script>
import AssignmentUserRepository from "../../Repository/AssignmentUser";
import AssessmentRubricRepository from "../../Repository/AssessmentRubric";
import ExamSubjectDetailsAndQuestionsRepository from "../../Repository/ExamSubjectAndQuestion";
import { spacesUrl } from "../../NetworkManager";

export default {
  name: "activitySubmitAssessment",
  props: ["prop_item", "prop_subject"],
  data() {
    return {
      linkWater: '#E1E2F8',
      resolutionBlue: '#00128C',
      orange: '#FF4F1F',
      e1: 1,
      createNewSubmissionDialog: false,
      submissionTitle: '',
      submissionDescription: '',
      submissionFiles: [],
      attachedFiles: [],
      isMobileView: false,
      selectedSemester: {},
      userData: {},
      selectedInstituteId: '',
      filteredAssignments: [],
      rubricAsRemarkDialog: false,
      finalRubric: {},
      rubricAsRemark: [],
      submittedAssignmentsLoader: false,
      disableNewSubmission: true,
      timeExceeded: false,
      newAssessmentLoader: false,
      assessmentRubricDialog: false,
      remarkToView: '',
      allowedFileTypesString: '',
      assignmentQuestions: [],
      questionsPreviewArray: [],
      currentTotalMinutes: '',
      targetTotalMinutes: '',
      headers1: [
        {
          text: 'No',
          value: 'No'
          // width: "9%",
          // align: "center"
        },
        {
          text: "Question Statements and Section Names",
          value: "Question Statements and Section Names"
          // width: "9%",
          // align: "center"
        },
        {
          text: 'Marks',
          value: 'Marks'
          // width: "20%",
          // align: "center"
        }
      ]
    }
  },
  created() {
    console.log('prop_item', this.prop_item)
    this.$store.commit("liveData/set_selectedActivityName", "Submit Assessment");
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstituteId = this.$store.getters[
      "instituteData/get_selectedInstitute"
    ];
    this.ExamSubjectDetailsAndQuestionsRepositoryInstance = new ExamSubjectDetailsAndQuestionsRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);
    this.assessmentRubricRepositoryInstance = new AssessmentRubricRepository(this);

    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.fetchAssignmentQuestions()
    this.getSubmittedAssignments()
    if (this.prop_item.examType === 'Rubric') {
      this.getRubricDetails()
    }
    this.getAllowedFileTypes(this.prop_item.allowedFileTypes)

    const dueTimeString = this.prop_item.dueTime;
    const dateComponent = new Date(this.prop_item.dueDate);

    var timeComponents = dueTimeString.match(/(\d+):(\d+)\s+(\w+)/);
    var hours = parseInt(timeComponents[1], 10);
    var minutes = parseInt(timeComponents[2], 10);
    var ampm = timeComponents[3];

    if (ampm.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    }

    var timeComponent = new Date();
    timeComponent.setHours(hours);
    timeComponent.setMinutes(minutes);
    timeComponent.setSeconds(0);
    timeComponent.setMilliseconds(0);

    var combinedDateTime = new Date(dateComponent);
    combinedDateTime.setHours(timeComponent.getHours());
    combinedDateTime.setMinutes(timeComponent.getMinutes());

    console.log('new Date()', new Date(), new Date() > combinedDateTime, combinedDateTime)
    this.timeExceeded = new Date() > combinedDateTime; //&& this.targetTotalMinutes < new Date().getHours() * 60 + new Date().getMinutes()
    console.log('this.disableNewSubmission', this.disableNewSubmission)
  },
  methods: {
    async fetchAssignmentQuestions() {
      const assignmentQuestions = await this.ExamSubjectDetailsAndQuestionsRepositoryInstance.getExamSubjectQuestions({
        instituteId: this.prop_item.instituteId,
        examId: this.prop_item.examId,
        subjectId: this.prop_item.subjectIds[0]
      })
      this.assignmentQuestions = assignmentQuestions.result.allQuestions
      for (let i = 0; i < this.assignmentQuestions.length; i++) {
        const question1 = this.assignmentQuestions[i];

        if (question1.questionType.includes('Or')) {
          this.questionsPreviewArray.push({
            No: '',
            'Question Statements and Section Names': 'OR',
            Marks: ''
          });
        }

        this.questionsPreviewArray.push({
          No: question1.questionNumber,
          'Question Statements and Section Names': question1.questionStatement,
          Marks: question1.questionMarks
        });

        if (question1?.question?.length > 0) {
          for (let j = 0; j < question1.question.length; j++) {
            const question2 = question1.question[j];

            if (question2.questionType.includes('Or')) {
              this.questionsPreviewArray.push({
                No: '',
                'Question Statements and Section Names': 'OR',
                Marks: ''
              });
            }

            this.questionsPreviewArray.push({
              No: question2.questionNumber,
              'Question Statements and Section Names': question2.questionStatement,
              Marks: question2.questionMarks
            });

            if (question2?.question?.length > 0) {
              for (let k = 0; k < question2.question.length; k++) {
                const question3 = question2.question[k];

                if (question3.questionType.includes('Or')) {
                  this.questionsPreviewArray.push({
                    No: '',
                    'Question Statements and Section Names': 'OR',
                    Marks: ''
                  });
                }

                this.questionsPreviewArray.push({
                  No: question3.questionNumber,
                  'Question Statements and Section Names': question3.questionStatement,
                  Marks: question3.questionMarks
                });

                if (question3?.question?.length > 0) {
                  for (let m = 0; m < question3.question.length; m++) {
                    const question4 = question3.question[m];

                    if (question4.questionType.includes('Or')) {
                      this.questionsPreviewArray.push({
                        No: '',
                        'Question Statements and Section Names': 'OR',
                        Marks: ''
                      });
                    }

                    this.questionsPreviewArray.push({
                      No: question4.questionNumber,
                      'Question Statements and Section Names': question4.questionStatement,
                      Marks: question4.questionMarks
                    });
                  }
                }
              }
            }
          }
        }
      }

      console.log('assignmentQuestions', this.questionsPreviewArray)
    },
    gotoAssessments() {
      const subObj = {
        subjectId: this.prop_item.subjectIds[0],
        subjectName: this.prop_subject.subjectName,
        department: this.prop_subject.department,
        courseYear: this.prop_subject.courseYear,
        subjectType: this.prop_subject.subjectType
      }
      if (this.isMobileView) {
        this.$router.push({
          name: "activityAssignments2",
          params: {
            prop_subject: subObj
          }
        });
      } else {
        this.$router.push({
          name: "activityAssignments",
          params: {
            prop_subject: subObj
          }
        });
      }
    },
    sumObtainedMarks(data) {
      // Assuming data is an array of submissions
      if (Array.isArray(data) && data.length > 0) {
        // Accessing obtained marks for each question in the first submission
        const submission = data[0]; // Considering only the first submission for this example

        if (submission && submission.questions && submission.questions.length > 0) {
          const sum = submission.questions.reduce((total, question) => {
            // Parsing obtained marks to numbers and summing them
            return total + parseInt(question.obtainedMarks || 0, 10);
          }, 0);

          return sum;
        } else {
          return 0; // No marks available, return 0
        }
      } else {
        return 0; // Invalid input or empty array, return 0
      }
    },
    getAllowedFileTypes(allowedFileTypes) {
      // console.log('allowedFileTypes', allowedFileTypes);
      // Parses to html accpet attribute string
      // eg: accept="image/*,video/*,.pdf"
      // which will acept all images videos and pdfs

      if (this.prop_item.examType === 'Non rubric') {

        this.allowedFileTypesString = "";
        if (allowedFileTypes.length === 0) {
          return "*"
        }
        if (allowedFileTypes.includes('image')) {
          this.allowedFileTypesString += "image/*"
        }
        if (allowedFileTypes.includes('All')) {
          this.allowedFileTypesString += "*"
          return "*"
        }
        if (allowedFileTypes.includes('audio')) {
          if (this.allowedFileTypesString.length !== 0) {
            this.allowedFileTypesString += ",audio/*"
          } else this.allowedFileTypesString += "audio/*"
        }

        if (allowedFileTypes.includes('video')) {
          if (this.allowedFileTypesString.length !== 0) {
            this.allowedFileTypesString += ",video/*"
          } else this.allowedFileTypesString += "video/*"
        }

        if (allowedFileTypes.includes('pdf')) {
          if (this.allowedFileTypesString.length !== 0) {
            this.allowedFileTypesString += ",.pdf"
          } else this.allowedFileTypesString += ".pdf"
        }
      } else {
        this.prop_item.allowedFileTypes.forEach((item) => {
          this.allowedFileTypesString += `,${item.extension}`
        })
      }
      return this.allowedFileTypesString;
    },
    viewRubric() {
      this.assessmentRubricDialog = true
    },
    async createNewSubmission() {
      this.newAssessmentLoader = true
      await this.uploadSubmissionFiles()
      const fileLinks = []
      this.submissionFiles.forEach((file) => {
        fileLinks.push({ submittedFile: file, checkedFile: {} })
      })
      const objToPush = {
        instituteId: this.selectedInstituteId,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId,
        assignmentId: this.prop_item.examId,
        linksOfAttachments: fileLinks,
        submittedOn: new Date().toISOString(),
        status: 'Submitted',
        title: this.submissionTitle,
        description: this.submissionDescription,
        assignmentType: this.prop_item.examType,
        subjectId: this.prop_item.subjectIds[0],
        examId: this.prop_item.examId,
        prnNumber: this.userData.collegePRNNo
      };
      await this.assignmentUserRepositoryInstance.createAssignmentUser(objToPush);
      this.getSubmittedAssignments()
      this.createNewSubmissionDialog = false
      this.newAssessmentLoader = false
    },
    clearSubmissionFields() {
      this.submissionTitle = ''
      this.submissionDescription = ''
      this.submissionFiles = []
      this.createNewSubmissionDialog = false
    },
    async uploadSubmissionFiles() {
      this.submissionFiles = await this.uploadFiles();
    },
    async uploadFiles() {
      if (this.attachedFiles.length === 0) return [];

      const attachedFilePromises = [];
      const attachedFiles = this.attachedFiles.filter((item) => item.name);

      attachedFiles.forEach((attachedFile) => {
        attachedFilePromises.push(
          this.getSignedUrl(
            attachedFile,
            `${this.userData.uId}/assignmentSubmissionFiles/`
          )
        );
      });
      const signedUrlsData = await Promise.all(attachedFilePromises);
      const signedUrls = signedUrlsData.map((item) => item.signedUrl);

      const uploadPromises = [];
      if (signedUrls.length === attachedFiles.length) {
        attachedFiles.forEach((attachedFile, i) => {
          uploadPromises.push(this.uploadToSpaces(attachedFile, signedUrls[i]));
        });

        await Promise.all(uploadPromises);
        return signedUrlsData.map((item, i) => ({
          url: spacesUrl + "/" + item.fileName,
          name: attachedFiles[i].name,
          mimeType: item.mimeType
        }));
      }
      return [];
    },
    async getSignedUrl(file, path = "") {
      const fileName = path + file.name;
      const body = {
        fileName,
        fileType: file.type
      };
      const signedUrl = await this.assignmentUserRepositoryInstance.getSignedUrl(
        body
      );
      return signedUrl;
    },
    async uploadToSpaces(file, signedUrl) {
      const res = await this.futch(
        signedUrl,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read"
          }
        },
        (event) => {
          const progress = parseInt((event.loaded / event.total) * 100);
          setTimeout(() => {
            console.log(event);
            this.currentUploadProgress = progress;
            if (progress > 99) {
              this.currentlyUploadingNumber++;
            }
          }, 200);
        }
      );
      this.currentUploadProgress = 0;

      return res;
    },
    futch(url, opts = {}, onProgress) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method || "get", url);
        for (var k in opts.headers || {}) {
          xhr.setRequestHeader(k, opts.headers[k]);
        }
        xhr.onload = (e) => resolve(e.target.responseText);
        xhr.onerror = reject;
        if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        xhr.send(opts.body);
      });
    },
    async getSubmittedAssignments() {
      this.submittedAssignmentsLoader = true
      let allSubmittedAssignments
      try {
        const objToPush = {
          instituteId: this.selectedInstituteId,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId
        };
        allSubmittedAssignments = await this.assignmentUserRepositoryInstance.getSubmittedAssignmentsOfAnUser(
          objToPush
        );
        this.filteredAssignments = await allSubmittedAssignments.filter(e => e.examId === this.prop_item.examId)

        if (this.filteredAssignments.length > 0) {
          (this.filteredAssignments[this.filteredAssignments.length - 1].status === 'Submitted' || this.filteredAssignments[this.filteredAssignments.length - 1].status === 'Completed') && !this.filteredAssignments[this.filteredAssignments.length - 1]?.resubmit ? this.disableNewSubmission = true : this.disableNewSubmission = false
        } else {
          this.disableNewSubmission = false
        }
        this.filteredAssignments.sort(function (a, b) {
          return new Date(b.submittedOn) - new Date(a.submittedOn);
        });
        this.submittedAssignmentsLoader = false
        if (this.prop_item.deadlineForSubmission < new Date()) {
          this.disableNewSubmission = true
        }
      } catch (e) {
        console.log('e', e);
        this.disableNewSubmission = false
        this.submittedAssignmentsLoader = false
        this.filteredAssignments = []
      }
    },
    viewRubricAsRemark(doc) {
      this.rubricAsRemarkDialog = true
      if (this.prop_item.examType === 'Rubric') {
        this.rubricAsRemark = []
        this.finalRubric.forEach((criteria) => {
          doc.rubricCriteriaMarks.forEach((item) => {
            if (item.criteria === criteria.criteriaTitle) {
              // array = criteria.limits.filter(limit => item.marks >= limit.lowerLimit && item.marks <= limit.upperLimit)
              criteria.limits.forEach((limit) => {
                if (Number(item.marks) >= Number(limit.lowerLimit) && Number(item.marks) <= Number(limit.upperLimit)) {
                  this.rubricAsRemark.push({
                    scaleDescription: limit.description,
                    criteriaTitle: criteria.criteriaTitle,
                    criteriaDescription: criteria.criteriaDescription,
                    criteriaMarks: item.marks,
                    maxCriteriaMarks: this.prop_item.maxMarksForAssessmentCriteria
                  })
                }
              })
            }
          })
        })
      } else {
        this.remarkToView = doc.remark
      }
    },
    async getRubricDetails() {
      const response = await this.assessmentRubricRepositoryInstance.getAssessmentRubricById({
        rubricId: this.prop_item.rubricId,
        semId: this.prop_item.semId,
        instituteId: this.prop_item.instituteId
      })
      this.finalRubric = response.rubric.rubric.rubric
    },
    splitStoreUrl(url) {
      if (url !== undefined && typeof (url) !== 'object') {
        return url.split("________")[1].split("?")[0].replaceAll("%", '')
      } else {
        return ''
      }
    }
  }
};
</script>
<style src="./activitySubmitAssessment.css"></style>
