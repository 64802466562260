<template src="./activityAssignments.html"></template>
<script>
import auth from "../../Services/Firebase/auth";
import AssignmentRepository from "../../Repository/Assignment";
import AssignmentUserRepository from "../../Repository/AssignmentUser";
import AssessmentRepository from "../../Repository/Assessment";
import { convertDate, convertUTCDateToLocalDate } from "../../Services/Utils/DateTimeFormatter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import firebase from "firebase";

var firestorage = null;
export default {
  name: "activityAssignments",
  props: ["prop_subject"],
  data() {
    return {
      // filteredAssignments: [],
      disabledAddfiles: false,
      studentuId: '',
      isMobileView: false,
      editor: ClassicEditor,
      editorConfig: { toolbar: [] },
      userData: {},
      assignments: [],
      headers: [
        {
          text: "Name Of Assignment",
          value: "title",
          width: "200px",
          align: "left"
        },
        {
          text: "Published On",
          value: "publishDate",
          width: "130px",
          align: "left"
        },
        {
          text: "Due",
          value: "dueDate",
          width: "120px",
          align: "left"
        },
        {
          text: "Time",
          value: "dueTime",
          width: "120px",
          align: "left"
        },
        // {
        //   text: "Attachments",
        //   value: "linksOfAttachments",
        //   width: "140px",
        //   align: "left"
        // },
        // {
        //   text: "Allowed File Types",
        //   value: "allowedFileTypes",
        //   width: "180px",
        //   align: "center"
        // },
        {
          text: "Marks",
          value: "totalMarksObtained",
          width: "100px",
          align: "left"
        },
        {
          text: "Submitted On",
          value: "submittedOn",
          width: "130px",
          align: "left"
        },
        // {
        //   text: "Submitted Files",
        //   value: "submittedFiles",
        //   width: "140px",
        //   align: "left"
        // },
        // {
        //   text: "Remarks",
        //   value: "remark",
        //   width: "200px",
        //   align: "left"
        // },
        {
          text: "Status",
          value: "status",
          width: "140px",
          align: "center"
        },
        {
          text: "Action",
          value: "action",
          width: "140px",
          align: "center"
        }
      ],
      showAssignmentDialog: false,
      selectedAssignment: {},
      files: [],
      uploadTask: "",
      uploadEnd: false,
      downloadURLs: [],
      uploadCount: 0,
      fileName: "",
      uploading: false,
      selectedInstitute: "",
      selectedSemester: {},
      loading: false,
      progressUpload: 0,
      showRemarksDialog: false,
      updateSubmittedFiles: false,
      showMarks: null
    };
  },
  async created() {
    this.$store.commit("liveData/set_selectedActivityName", "Assignments");
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    const firebase = auth.getFirebaseObject();
    firestorage = firebase.storage();
    // console.log(this.prop_subject);
    this.userData = this.$store.getters["user/get_userData"];
    // console.log('this.userData', this.userData.uId);
    this.studentuId = this.userData.uId;
    this.selectedInstitute = this.$store.getters[
      "instituteData/get_selectedInstitute"
    ];
    this.selectedSemester = this.$store.getters[
      "instituteData/get_selectedSemester"
    ];
    // console.log('this.userData', this.userData);
    // console.log('this.prop_subject', this.prop_subject);
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);

    this.assessmentRepositoryInstance = new AssessmentRepository(this);
    this.assignments = [];
    console.log('this.selectedInstitute', this.selectedInstitute);
    const infoData = await this.getInstituteCustomizationFromFirebase(this.selectedInstitute)
    this.showMarks = infoData
    console.log('infoData', infoData)
    this.fetchData();
    // this.getAllAssessments()
    // this.compareDateTime()
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadEnd = true;
            this.downloadURLs.push(downloadURL);
            this.uploadCount++;
            if (this.uploadCount === this.files?.length) {
              if (this.selectedAssignment.submittedFiles) {
              } else {
                this.selectedAssignment.submittedFiles = [];
              }
              const indexOfAssignment = this.assignments.indexOf(this.selectedAssignment)
              this.downloadURLs.forEach(newUrl => {
                this.selectedAssignment.submittedFiles.push({
                  submittedFile: newUrl,
                  checkedFile: ""
                });
              });
              this.assignments[indexOfAssignment].submittedFiles = this.selectedAssignment.submittedFiles
              this.assignData(this.assignments)
              this.showAssignmentDialog = false
              this.loading = false
              this.progressUpload = 0
            } else {
              this.progressUpload = 0;
              this.upload(this.uploadCount);
            }
          });
        }
      );
    }
  },
  // mounted: function () {
  //   console.log('entered in assignment');
  //   this.$tours['activityAssignments'].start()
  // },
  methods: {
    async getInstituteCustomizationFromFirebase(instituteId) {
      let infoData = [];
      // Specify the ID you want to check

      // Reference the collection and the specific document
      const docRef = firebase
        .database()
        .ref("/instituteCustomization/" + instituteId);
      // Get the data for the specific document
      await docRef
        .once("value")
        .then(function (snapshot) {
          if (snapshot.exists()) {
            // The document with the specified ID exists
            infoData = snapshot.val();
          } else {
            // The document with the specified ID does not exist
            console.log("Document does not exist!");
          }
        })
        .catch(function (error) {
          console.log("Error fetching document data:", error);
        });
      return infoData;
    },
    compareDateTimeForSubmitBtn(item) {
      if (item.time) {
        let today = new Date()
        today = today.toISOString()
        // console.log('today', today);
        let assignmentTimeDate = item.deadlineForSubmission
        const receivedTime = item.time
        assignmentTimeDate = assignmentTimeDate.toISOString()
        let [year, month, date] = assignmentTimeDate.slice(0, 10)?.split('-')
        let [hour, minute] = receivedTime?.split(':')
        year = Number(year)
        month = Number(month) - 1
        date = Number(date)
        hour = Number(hour)
        minute = Number(minute)
        // console.log('Operation', date, hour, minute);
        let createDateByItem = new Date(year, month, date, hour, minute, 0, 0)
        // console.log('date', year, month, date);
        createDateByItem = createDateByItem.toISOString()
        // console.log('assignmentTimeDate', assignmentTimeDate, receivedTime);
        // console.log('createDateByItem', createDateByItem, today);
        // console.log('compareTimeDate', today, assignmentTimeDate);
        if (createDateByItem < today) {
          // console.log('1');
          return true
        } else {
          // console.log('2');
          return false
        }
      }
      if (new Date() < item.deadlineForSubmission) {
        // console.log('3');
        return false
      } else {
        // console.log('4');
        return true
      }
    },
    calculateSumOfMarks(item) {
      const questions = item.questions;
      const sumOfMarks = questions.reduce((acc, question) => acc + parseInt(question.marks), 0);
      return sumOfMarks;
    },
    sumObtainedMarks(data) {
      // Assuming data is an array of submissions
      if (Array.isArray(data) && data?.length > 0) {
        // Accessing obtained marks for each question in the first submission
        const submission = data[0]; // Considering only the first submission for this example

        if (submission && submission.questions && submission.questions?.length > 0) {
          const sum = submission.questions.reduce((total, question) => {
            // Parsing obtained marks to numbers and summing them
            return total + parseInt(question.obtainedMarks || 0, 10);
          }, 0);

          return sum;
        } else {
          return 0; // No marks available, return 0
        }
      } else {
        return 0; // Invalid input or empty array, return 0
      }
    },
    compareDateTime(item) {
      if (item.time) {
        let today = new Date()
        today = today.toISOString()
        // console.log('today', today);
        let assignmentTimeDate = item.deadlineForSubmission
        const receivedTime = item.time
        assignmentTimeDate = assignmentTimeDate.toISOString()
        let [year, month, date] = assignmentTimeDate.slice(0, 10)?.split('-')
        let [hour, minute] = receivedTime?.split(':')
        year = Number(year)
        month = Number(month) - 1
        date = Number(date)
        hour = Number(hour)
        minute = Number(minute)
        // console.log('Operation', date, hour, minute);
        let createDateByItem = new Date(year, month, date, hour, minute, 0, 0)
        createDateByItem = createDateByItem.toISOString()
        // console.log('date', year, month, date);
        // createDateByItem = createDateByItem.toDateString()
        // console.log('assignmentTimeDate', assignmentTimeDate, receivedTime);
        // console.log('createDateByItem', createDateByItem);
        // console.log('compareTimeDate', today, assignmentTimeDate);
        if (createDateByItem < today) {
          // console.log('1');
          return false
        } else {
          // console.log('2');
          return true
        }
      }
      if (new Date() < item.deadlineForSubmission) {
        // console.log('3');
        return true
      } else {
        // console.log('4');
        return false
      }
    },
    convertUTCDateToLocalDate(date) {
      return convertUTCDateToLocalDate(date)
    },
    convertTo12HourFormat(time24) {
      // Split the time string into hours and minutes
      const [hours, minutes] = time24?.split(':');

      // Convert hours to 12-hour format
      let hours12 = parseInt(hours, 10) % 12;
      hours12 = hours12 === 0 ? 12 : hours12; // If hours is 0, set it to 12

      // Determine whether it's AM or PM
      const period = parseInt(hours, 10) < 12 ? 'am' : 'pm';

      // Create the formatted time string
      const time12 = `${hours12}:${minutes} ${period}`;

      return time12;
    },
    async fetchData() {
      // if (this.showMarks?.length === 0 || this.showMarks.showMarks) {
        let assignments = [];
        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.userData.department,
            courseYear: this.userData.courseYear,
            division: this.userData.division,
            batch: this.userData.batch,
            subjectId: this.prop_subject.subjectId,
            todaysDate: new Date().toISOString()
          };
          assignments = await this.assignmentRepositoryInstance.getAssignmentsOfADivisionOfASubject(
            objToPush
          );
          if (this.userData.batch && this.userData.batch !== '') {
            let dummy = []
            if (assignments?.length > 0) {
              dummy = assignments.filter((data) => data.batch === this.userData.batch)
              dummy.forEach((data) => {
                data.dueTime = this.convertTo12HourFormat(data.dueTime);
              })
            }
            assignments = dummy
          }
          // assigned student logic
          assignments.map((assignment, index) => {
            if (assignment?.assignedStudentList?.length > 0) {
              // console.log('assignment', assignment);
              if (!assignment.assignedStudentList.includes(this.studentuId)) {
                // console.log('hello');
                assignments.splice(index, 1)
              }
            }
          })
          // console.log('assignmentsbefore', assignments);
          assignments.sort(function (a, b) {
            return new Date(b.dateOfAssignment) - new Date(a.dateOfAssignment)
          });
          for (let i = 0; i < assignments?.length; i++) {
            assignments[i].deadlineForSubmission = new Date(
              assignments[i].deadlineForSubmission
            );
            assignments[i].dateOfAssignment = new Date(
              assignments[i].dateOfAssignment
            );
          }
          // console.log('assignments after', assignments)
        } catch (err) {
          console.log(err);
        }

        try {
          const objToPush = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            uId: this.userData.uId,
            showMarks: this.showMarks
          };
          let allSubmittedAssignments = []
          try {
            allSubmittedAssignments = await this.assignmentUserRepositoryInstance.getSubmittedAssignmentsOfAnUser(
              objToPush
            );
          } catch (e) {
            // console.log('e', e);
            allSubmittedAssignments = []
          }
          console.log("allSubmittedAssignments", allSubmittedAssignments);
          // console.log("assignments", assignments);
          for (let i = 0; i < assignments?.length; i++) {
            const submittedAssignments = allSubmittedAssignments.filter(
              submittedAssignment =>
                submittedAssignment.examId ===
                assignments[i].examId
            );
            const submittedAssignment =
              submittedAssignments[submittedAssignments?.length - 1];
            if (submittedAssignment) {
              console.log('here', submittedAssignment)
              assignments[i].submittedFiles =
                submittedAssignment.linksOfAttachments;
              assignments[i].submittedOn = submittedAssignment.submittedOn
                ? new Date(submittedAssignment.submittedOn)
                : "---";
              assignments[i].totalMarksObtained =
                submittedAssignment.totalMarksObtained;
              assignments[i].status = submittedAssignment.status ? submittedAssignment.status : "Submitted";
              assignments[i].remark = submittedAssignment.remark ? submittedAssignment.remark : "---";
              assignments[i].remarkAttachments = submittedAssignment.remarkAttachments
                ? submittedAssignment.remarkAttachments
                : [];
            } else {
              assignments[i].status = "Pending";
              assignments[i].remark = "";
              assignments[i].remarkAttachments = [];
            }
          }
        } catch (err) {
          console.log(err);
        }
        this.assignData(assignments.slice())
      // }
    },
    assignData(assignments) {
      this.updateSubmittedFiles = false
      assignments.map((item) => {
        item['action'] = 'mdi-arrow-right'
      })
      console.log('assignments', assignments)
      this.assignments = assignments.reverse();
      this.updateSubmittedFiles = true
    },
    convertDate(dateString) {
      return convertDate(dateString);
    },
    splitStoreUrl(url) {
      // console.log('url', url)s;
      if (url !== undefined && typeof (url) !== 'object') {
        return url?.split("________")[1]?.split("?")[0].replaceAll("%", '')
      } else {
        return ''
      }
    },
    uploadFiles() {
      this.loading = true;
      this.downloadURLs = [];
      this.uploadCount = 0;
      // console.log(this.files);
      this.upload(this.uploadCount);
    },
    upload(fileNumber) {
      // console.log("uploading");
      this.fileName = this.files[fileNumber].name;
      this.uploading = true;
      this.uploadTask = firestorage
        .ref(
          "assignments/" +
          this.userData.uId +
          "/" +
          new Date().valueOf().toString() +
          "_submitted" +
          "________" +
          this.files[fileNumber].name
        )
        .put(this.files[fileNumber]);
    },
    /*
      filesChanges method returns an array of files rather
      than an event object as in html spec.
      This is vuetify specific
      Ref: https://vuetifyjs.com/en/api/v-file-input/#events-change

      NOTE: you may see the function running two times,
      this is a normal behaviour because this
      function runs on receiving input from user
      and when this.files changes/updates.
    */
    filesChanged(inputFiles) {
      const { allowedFileTypes } = this.selectedAssignment
      //For backward compatibility, when assignments had no
      //file type restrictions
      // console.log('inputFiles', inputFiles);
      if (allowedFileTypes?.length === 0) {
        this.files = inputFiles
        return;
      }
      if (allowedFileTypes.includes('All')) {
        this.files = inputFiles
        return;
      }
      // console.log('this.files', this.files);
      this.files = []
      for (let i = 0; i < inputFiles?.length; i++) {
        for (let j = 0; j < allowedFileTypes?.length; j++) {
          /*
            check if the files uploaded by user are of
            required types only
          */
          if (inputFiles[i].type.match(allowedFileTypes[j])) {
            this.files.push(inputFiles[i])
            break;
          }
        }
      }
    },
    reAttempAssignment(assignment) {
      // console.log('this.assignments.indexOf(assignment)', this.assignments.indexOf(assignment));
      // console.log("assignment", assignment);
      this.files = [];
      this.selectedAssignment = assignment;
      this.showAssignmentDialog = true;
    },
    attempAssignment(assignment) {
      // console.log('this.assignments.indexOf(assignment)', this.assignments.indexOf(assignment));
      this.files = [];
      this.selectedAssignment = assignment;
      this.showAssignmentDialog = true;
    },
    async removeAttachment(link, assignment) {
      this.selectedAssignment = assignment;
      const indexOfAssignment = this.assignments.indexOf(this.selectedAssignment)
      if (this.selectedAssignment?.submittedFiles?.length !== 0) {
        for (
          let i = 0;
          i < this.selectedAssignment.submittedFiles?.length;
          i++
        ) {
          if (
            this.selectedAssignment.submittedFiles[i].submittedFile === link
          ) {
            this.selectedAssignment.submittedFiles.splice(i, 1);
          }
        }
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          assignmentId: this.selectedAssignment.examId,
          linksOfAttachments: this.selectedAssignment.submittedFiles,
          assignmentType: 'Normal'
        };
        // console.log(objToPush);
        try {
          const response = await this.assignmentUserRepositoryInstance.createAssignmentUser(
            objToPush
          );
          console.log('response', response);
        } catch (e) {
          console.log('e', e);
        }
        this.assignments[indexOfAssignment].submittedFiles = this.selectedAssignment.submittedFiles
        this.assignData(this.assignments)
      } else {
        window.alert(
          "You cannot delete attachment in the assignment. Add new attachment to delete previous one."
        );
      }
    },
    async submitAssignment() {
      try {
        // console.log('new Date()', new Date().toISOString());
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          uId: this.userData.uId,
          assignmentId: this.selectedAssignment.examId,
          linksOfAttachments: this.selectedAssignment.submittedFiles,
          submittedOn: new Date().toISOString()
        };
        if (this.selectedAssignment.status) {
          objToPush.status = "Submitted"
        }
        // console.log(objToPush);
        const response = await this.assignmentUserRepositoryInstance.createAssignmentUser(
          objToPush
        );
        const indexOfAssignment = this.assignments.indexOf(
          this.selectedAssignment
        );
        this.assignments[indexOfAssignment].submittedFiles =
          response.linksOfAttachments;
        this.assignments[indexOfAssignment].submittedOn = convertDate(
          response.submittedOn
        );
        this.assignments[indexOfAssignment].status = "Submitted";

        this.loading = false;
        this.showAssignmentDialog = false;
        this.fetchData();
      } catch (err) {
        console.log(err);
      }
    },
    downloadAttachments(item, index) {
      // console.log(item, index);
      this.download(item.title, item.linksOfAttachments[index]);
    },
    download(filename, text) {
      if (!text) {
        return;
      }
      var element = document.createElement("a");
      element.href = text
      // element.setAttribute('download', `${this.splitStoreUrl(text)}`);
      // element.style.display = "none";
      // console.log('filename', filename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    convertDateTime(item) {
      return convertDate(item);
    },
    getAllowedFileTypes(allowedFileTypes) {
      // console.log('allowedFileTypes', allowedFileTypes);
      // Parses to html accpet attribute string
      // eg: accept="image/*,video/*,.pdf"
      // which will acept all images videos and pdfs

      let allowedFileTypesString = "";
      if (allowedFileTypes?.length === 0) {
        return "*"
      }
      if (allowedFileTypes.includes('image')) {
        allowedFileTypesString += "image/*"
      }
      if (allowedFileTypes.includes('All')) {
        allowedFileTypesString += "*"
        return "*"
      }
      if (allowedFileTypes.includes('audio')) {
        if (allowedFileTypesString?.length !== 0) {
          allowedFileTypesString += ",audio/*"
        } else allowedFileTypesString += "audio/*"
      }

      if (allowedFileTypes.includes('video')) {
        if (allowedFileTypesString?.length !== 0) {
          allowedFileTypesString += ",video/*"
        } else allowedFileTypesString += "video/*"
      }

      if (allowedFileTypes.includes('pdf')) {
        if (allowedFileTypesString?.length !== 0) {
          allowedFileTypesString += ",.pdf"
        } else allowedFileTypesString += ".pdf"
      }
      return allowedFileTypesString;
    },
    openFile(fileUrl) {
      window.open(fileUrl).focus()
    },
    // gotoStudyMaterial() {
    //   // this.$store.commit("liveData/set_selectedSubject", this.prop_subject);
    //   if (this.isMobileView) {
    //     this.$router.push({
    //       name: "activityStudyMaterial2",
    //       params: {
    //         prop_subject: this.prop_subject
    //       }
    //     });
    //   } else {
    //     this.$router.push({
    //       name: "activityStudyMaterial",
    //       params: {
    //         prop_subject: this.prop_subject
    //       }
    //     });
    //   }
    // }
    gotoAssignment(item) {
      console.log('item', item);
      if (this.isMobileView) {
        this.$router.push({
          name: "activitySubmitAssessment2",
          params: {
            prop_item: item,
            prop_subject: this.prop_subject
          }
        });
      } else {
        this.$router.push({
          name: "activitySubmitAssessment",
          params: {
            prop_item: item,
            prop_subject: this.prop_subject
          }
        });
      }
    }
  }
};
</script>
<style src="./activityAssignments.css"></style>
